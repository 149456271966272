import React, { useState } from "react";

import { useTranslation, Trans } from "react-i18next";

import Button from '../Button'

import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';


function SwiperSW () {

    const { t } = useTranslation();

    const [swiperInstance, setSwiperInstance] = useState(null);

    const swiperData = [
        {
            title: t('forex_title'),
            texttitle: t('forex_texttitle'),
            t1: t('forex_t1'),
            t2: t('forex_t2'),
            t3: t('forex_t3'),
        },
        {
            title: t('commodities_title'),
            texttitle: t('commodities_texttitle'),
            t1: t('commodities_t1'),
            t2: t('commodities_t2'),
            t3: t('commodities_t3'),
            t4: t('commodities_t4'),
            t5: t('commodities_t5'),
        },
        {
            title: t('stock_market_title'),
            texttitle: t('stock_market_texttitle'),
            t1: t('stock_market_t1'),
            t2: t('stock_market_t2'),
            t3: t('stock_market_t3'),
            t4: t('stock_market_t4'),
            t5: t('stock_market_t5'),
            t6: t('stock_market_t6'),
            t7: t('stock_market_t7'),
            t8: t('stock_market_t8'),
            t9: t('stock_market_t9'),
        },
        {
            title: t('indices_title'),
            texttitle: t('indices_texttitle'),
            t1: t('indices_t1'),
            t2: t('indices_t2'),
            t3: t('indices_t3'),
            t4: t('indices_t4'),
            t5: t('indices_t5'),
            t6: t('indices_t6'),
            t7: t('indices_t7'),
            t8: t('indices_t8'),
            t9: t('indices_t9'),
            t10: t('indices_t10'),
            t11: t('indices_t11'),
            t12: t('indices_t12'),
        },
        {
            title: t('vip_accounts_title'),
            t1: t('vip_accounts_t1'),
            t2: t('vip_accounts_t2'),
            t3: t('vip_accounts_t3'),
            t4: t('vip_accounts_t4'),
            t5: t('vip_accounts_t5'),
            t6: t('vip_accounts_t6'),
            t7: t('vip_accounts_t7'),
            t8: t('vip_accounts_t8'),
            t9: t('vip_accounts_t9'),
            t10: t('vip_accounts_t10'),
            t11: t('vip_accounts_t11'),
            t12: t('vip_accounts_t12'),
        },
    ];

    return(
        <div className="relative overflow-hidden bg-black mt-[80px] xl:mt-[160px]">
            <div className="max-w-[1280px] xl:mx-auto mx-[20px] mt-[120px] xl:mt-[200px]">
                <Swiper
                    grabCursor={true}
                    slidesPerView={1}
                    loop={true}
                    spaceBetween={20}
                    onSwiper={(swiper) => setSwiperInstance(swiper)}
                    breakpoints={{
                        1280: {
                            slidesPerView: 3,
                        },
                        600: {
                            slidesPerView: 2,
                        },
                        100: {
                            slidesPerView: 1,
                        },
                    }}
                >
                    {swiperData.map((slide, index) => (
                        <SwiperSlide key={index}>
                            <div className="flex flex-col">
                                <div className="flex flex-col items-center justify-center mx-auto h-[60px] md:h-[80px] xl:h-[117px] mb-[-30px] md:mb-[-40px] xl:mb-[-60px] bg-black z-50 relative border border-white" style={{ width: "calc(100% - 60px)" }}>
                                    <p className="text-[16px] md:text-[24px] xl:text-[32px] font-[500] text-white manrope uppercase">{slide.title}</p>
                                    <p className="text-[14px] xl:text-[16px] font-[500] text-white open-sans">{slide.texttitle}</p>
                                </div>
                                <div className={`border border-white h-[600px] pl-[36px] pb-[50px] bg-[#000000]`}>
                                    <table className="mt-[80px] text-white">
                                            <tr>
                                                <td className="roboto text-[16px] leading-[1.4] text-[#FFFFFFB2]">
                                                    {t(`accounttypes.11`)}
                                                </td>
                                                <td className="roboto text-[#B30101] text-[16px] leading-[1.4] font-[500] pl-[20px] py-[3px]">
                                                    {slide.t1}
                                                </td>
                                            </tr>
                                            <tr>
                                                <td className="roboto text-[16px] leading-[1.4] text-[#FFFFFFB2]">
                                                    {t(`accounttypes.12`)}
                                                </td>
                                                <td className="roboto text-[#FFFFFF] text-[16px] leading-[1.4] font-[500] pl-[20px] py-[3px] max-w-[205px]">
                                                    {slide.t2}
                                                </td>
                                            </tr>
                                            <tr>
                                                <td className="roboto text-[16px] leading-[1.4] text-[#FFFFFFB2]">
                                                    
                                                </td>
                                                <td className="roboto text-[#FFFFFF] text-[16px] leading-[1.4] font-[500] pl-[20px] py-[3px] max-w-[205px]">
                                                    {slide.t3}
                                                </td>
                                            </tr>
                                            <tr>
                                                <td className="roboto text-[16px] leading-[1.4] text-[#FFFFFFB2]">
                                                    
                                                </td>
                                                <td className="roboto text-[#FFFFFF] text-[16px] leading-[1.4] font-[500] pl-[20px] py-[3px] max-w-[205px]">
                                                    {slide.t4}
                                                </td>
                                            </tr>
                                            <tr>
                                                <td className="roboto text-[16px] leading-[1.4] text-[#FFFFFFB2]">
                                                    
                                                </td>
                                                <td className="roboto text-[#FFFFFF] text-[16px] leading-[1.4] font-[500] pl-[20px] py-[3px] max-w-[205px]">
                                                    {slide.t5}
                                                </td>
                                            </tr>
                                            <tr>
                                                <td className="roboto text-[16px] leading-[1.4] text-[#FFFFFFB2]">
                                                    
                                                </td>
                                                <td className="roboto text-[#FFFFFF] text-[16px] leading-[1.4] font-[500] pl-[20px] py-[3px] max-w-[205px]">
                                                    {slide.t6}
                                                </td>
                                            </tr>
                                            <tr>
                                                <td className="roboto text-[16px] leading-[1.4] text-[#FFFFFFB2]">
                                                    
                                                </td>
                                                <td className="roboto text-[#FFFFFF] text-[16px] leading-[1.4] font-[500] pl-[20px] py-[3px] max-w-[205px]">
                                                    {slide.t7}
                                                </td>
                                            </tr>
                                            <tr>
                                                <td className="roboto text-[16px] leading-[1.4] text-[#FFFFFFB2]">
                                                    
                                                </td>
                                                <td className="roboto text-[#FFFFFF] text-[16px] leading-[1.4] font-[500] pl-[20px] py-[3px] max-w-[205px]">
                                                    {slide.t8}
                                                </td>
                                            </tr>
                                            <tr>
                                                <td className="roboto text-[16px] leading-[1.4] text-[#FFFFFFB2]">
                                                    
                                                </td>
                                                <td className="roboto text-[#FFFFFF] text-[16px] leading-[1.4] font-[500] pl-[20px] py-[3px] max-w-[205px]">
                                                    {slide.t9}
                                                </td>
                                            </tr>
                                            <tr>
                                                <td className="roboto text-[16px] leading-[1.4] text-[#FFFFFFB2]">
                                                    
                                                </td>
                                                <td className="roboto text-[#FFFFFF] text-[16px] leading-[1.4] font-[500] pl-[20px] py-[3px] max-w-[205px]">
                                                    {slide.t10}
                                                </td>
                                            </tr>
                                            <tr>
                                                <td className="roboto text-[16px] leading-[1.4] text-[#FFFFFFB2]">
                                                    
                                                </td>
                                                <td className="roboto text-[#FFFFFF] text-[16px] leading-[1.4] font-[500] pl-[20px] py-[3px] max-w-[205px]">
                                                    {slide.t11}
                                                </td>
                                            </tr>
                                            <tr>
                                                <td className="roboto text-[16px] leading-[1.4] text-[#FFFFFFB2]">
                                                    
                                                </td>
                                                <td className="roboto text-[#FFFFFF] text-[16px] leading-[1.4] font-[500] pl-[20px] py-[3px] max-w-[205px]">
                                                    {slide.t12}
                                                </td>
                                            </tr>
                                    </table>
                                </div>
                                <Button className="mx-auto mt-[-30px]"/>
                            </div>
                        </SwiperSlide>
                    ))}
                </Swiper>
                <div className="flex gap-[20px] mt-[20px] xl:mt-[65px]">
                    <svg onClick={() => swiperInstance && swiperInstance.slidePrev()} className="cursor-pointer w-[35px] xl:w-auto" xmlns="http://www.w3.org/2000/svg" width="60" height="60" viewBox="0 0 60 60" fill="none">
                        <rect x="1" y="1" width="58" height="58" fill="black" stroke="white" stroke-width="2"/>
                        <path d="M14.2929 29.2929C13.9024 29.6834 13.9024 30.3166 14.2929 30.7071L20.6569 37.0711C21.0474 37.4616 21.6805 37.4616 22.0711 37.0711C22.4616 36.6805 22.4616 36.0474 22.0711 35.6569L16.4142 30L22.0711 24.3431C22.4616 23.9526 22.4616 23.3195 22.0711 22.9289C21.6805 22.5384 21.0474 22.5384 20.6569 22.9289L14.2929 29.2929ZM45 29L15 29L15 31L45 31L45 29Z" fill="white"/>
                    </svg>
                    <svg onClick={() => swiperInstance && swiperInstance.slideNext()} className="cursor-pointer w-[35px] xl:w-auto" xmlns="http://www.w3.org/2000/svg" width="60" height="60" viewBox="0 0 60 60" fill="none">
                        <rect x="1" y="1" width="58" height="58" fill="black" stroke="white" stroke-width="2"/>
                        <path d="M45.7071 30.7071C46.0976 30.3166 46.0976 29.6834 45.7071 29.2929L39.3431 22.9289C38.9526 22.5384 38.3195 22.5384 37.9289 22.9289C37.5384 23.3195 37.5384 23.9526 37.9289 24.3431L43.5858 30L37.9289 35.6569C37.5384 36.0474 37.5384 36.6805 37.9289 37.0711C38.3195 37.4616 38.9526 37.4616 39.3431 37.0711L45.7071 30.7071ZM15 31L45 31V29L15 29V31Z" fill="white"/>
                    </svg>
                </div>
            </div>
        </div>
    );
}

export default SwiperSW;