import React, { useState } from "react";

import { useTranslation, Trans } from "react-i18next";

import m1 from '../../img/accounts/main1.png'
import m2 from '../../img/accounts/main2.png'

const data = [
    {
        question: 'accounttypes.3',
        answer: 'accounttypes.4',
    },
    {
        question: 'accounttypes.5',
        answer: 'accounttypes.6',
    },
    {
        question: 'accounttypes.7',
        answer: 'accounttypes.8',
    },
    {
        question: 'accounttypes.9',
        answer: 'accounttypes.10',
    },
    {
        question: 'accounttypes.a9',
        answer: 'accounttypes.a10',
    },
]


function Main () {

    const { t } = useTranslation();

    const [openIndex, setOpenIndex] = useState(1);

    const toggleText = (index) => {
        setOpenIndex((prevIndex) => (prevIndex === index ? null : index));
    };
    
    
    return(
        <div className="relative overflow-hidden">
            <div className="max-w-[1280px] xl:mx-auto mx-[20px]">
                <div className="flex flex-col md:flex-row justify-between">
                    <div className="mt-[40px] md:mt-[120px]">
                        <p className="spanred leading-[1.2] font-[500] text-[40px] xl:text-[80px] manrope max-w-[517px]"><Trans>{t('accounttypes.1')}</Trans></p>
                        <p className="mt-[20px] xl:mt-[40px] roboto text-[16px] xl:text-[20px] text-[#000000B2] md:max-w-[489px]">{t('accounttypes.2')}</p>
                    </div>
                    <div className="mt-[20px] md:mt-[100px]">
                        <img src={m1} alt="" className="mx-auto md:mx-0"/>
                    </div>
                </div>
                <div className="flex flex-col xl:flex-row-reverse justify-between mt-[40px] xl:mt-[160px]">
                    <div className="xl:w-[497px]">
                        {data.map((faq, index) => (
                            <div key={index}>
                                <div className={`flex justify-between transition-all duration-300 ${openIndex === index ? 'h-auto' : 'h-[86px]'}`}>
                                    <div className="flex flex-col justify-center">
                                        <p className='manrope text-[20px] xl:text-[24px] text-[#000000] max-w-[200px] xl:max-w-[1280px]'>{t(faq.question)}</p>
                                        {openIndex === index && (
                                            <p className='text-[#000000B2] roboto text-[14px] xl:text-[17px] font-light mt-[12px] pb-[20px] max-w-[200px] xl:max-w-[802px]'>{t(faq.answer)}</p>
                                        )}
                                    </div>
                                    <div className='cursor-pointer mt-[30px] flex-shrink-0' onClick={() => toggleText(index)}>
                                        <svg className={` transition-all ${openIndex === index ? 'rotate-90' : 'rotate-0'}`} xmlns="http://www.w3.org/2000/svg" width="32" height="32" viewBox="0 0 32 32" fill="none">
                                            <rect x="1" y="1" width="30" height="30" fill="#B30101" stroke="#B30101" stroke-width="2"/>
                                            <path d="M24.7071 16.7071C25.0976 16.3166 25.0976 15.6834 24.7071 15.2929L18.3431 8.92893C17.9526 8.53841 17.3195 8.53841 16.9289 8.92893C16.5384 9.31946 16.5384 9.95262 16.9289 10.3431L22.5858 16L16.9289 21.6569C16.5384 22.0474 16.5384 22.6805 16.9289 23.0711C17.3195 23.4616 17.9526 23.4616 18.3431 23.0711L24.7071 16.7071ZM8 17L24 17V15L8 15V17Z" fill="white"/>
                                        </svg>
                                    </div>
                                </div>
                                <div className="h-[1px] w-full bg-[#000000B2]"></div>
                            </div>
                        ))}
                    </div>
                    <div className="mt-[20px] xl:mt-0">
                        <img src={m2} alt="" className="mx-auto xl:mx-0"/>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Main;